<script setup>
import { ref, onMounted, useSSRContext, watch, defineProps } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import Toast from 'primevue/toast'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/core/store/AuthStore'

const router = useRouter()
const auth = useAuthStore()
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()


//filters
const filter_tasks = ref({
    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
    'user': {value: null, matchMode: FilterMatchMode.EQUALS}
})

//data
const tasks = ref([])
const companies = ref([])
const users = ref([])
const details = ref()

const task_name = ref('')
const task_due_date = ref('')
const task_description = ref('')
const task_user = ref('')
const task_mode = ref("inbox")
const task_company = ref('')
const task_checklist = ref([])

const edit_due_date = ref(false)
const edit_date = ref(false)

const todo_edit_mode = ref(false)

const get_data = async() => {
    await Promise.all([
        axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/prosperita/get-tasks"),
        axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/prosperita/get-pipedrive-organizations"),
    ])
    .then(async([tasks_response, customers_response]) => {
        console.log(customers_response.data)
        companies.value = customers_response.data
        tasks.value = tasks_response.data
        task_tabs.value[0].badge = tasks_response.data.filter(task => task.user == auth.userdata.user_email).length
        task_tabs.value[1].badge = tasks_response.data.length
        if (task_tabs.value[0].badge > 0) {
            filter_tasks.value.user.value = auth.userdata.user_email
        }
        console.log("user auth", auth)
    })
}

const get_users = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-users')
    .then(response => {
        console.log(response.data)
        users.value = response.data
        loader.value = false
    })
}

//panels
const details_slider = ref(false)
const todo_task_dialog = ref(false)

//menus
const menu_new_task = ref()
const menu_new_task_items = ref([{
    label: 'Task type',
    items: [
        {label: 'Aufgabe', icon: 'pi pi-file', command: () => create_tasks("invoice")}
    ]
}])
const toggle_menu_new_task = (event) => menu_new_task.value.toggle(event)

const task_tabs = ref([
    {name: 'Meine Aufgaben', icon: 'pi pi-user', badge: 0},
    {name: 'Alle Aufgaben', icon: 'pi pi-users', badge: 0}
])

const set_task_filter = (task_name) => {
    if (task_name == 'Meine Aufgaben') {
        filter_tasks.value.user.value = auth.userdata.user_email
    } else {
        filter_tasks.value.user.value = null
    }
}

onMounted(() => {
    loader.value = true
    get_data()
    get_users()
    loader.value = false
})

const create_tasks = (type) => {
    console.log("create_tasks", type)
    todo_task_dialog.value = true
}

const save_task = () => {
    console.log("save_task")
    loader.value = true
    let payload = {
        name: task_name.value,
        due_date: task_due_date.value,
        description: task_description.value,
        user: task_user.value,
        company: task_company.value,
        type: 'Aufgabe',
        checklist: task_checklist.value
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/create-task', payload)
    .then(response => {
        console.log(response.data)
        todo_task_dialog.value = false
        get_data()
        loader.value = false
    })
}

const save_edit_task = () => {
    console.log("save_edit_task")
    loader.value = true
    let payload = {
        id: details.value.id,
        name: details.value.name,
        date: details.value.date,
        due_date: details.value.due_date,
        description: details.value.description,
        user: details.value.user,
        company: details.value.company,
        type: details.value.type,
        checklist: details.value.checklist
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/update-task', payload)
    .then(response => {
        console.log(response.data)
        details_slider.value = false
        get_data()
        loader.value = false
    })
}

const delete_task = () => {
    confirm.require({
        message: 'Möchten Sie diese Aufgabe wirklich löschen?',
        header: 'Aufgabe löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        acceptClass: 'p-button-danger',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/delete-task', {id: details.value.id})
            .then(response => {
                console.log(response.data)
                details_slider.value = false
                toast.add({severity: 'success', summary: 'Success', detail: 'Aufgabe gelöscht', life: 3000})
                get_data()
                loader.value = false
            })
        }
    })
}

const edit_checklist = (e) => {
    if (task_checklist.value[e.index][e.field] != e.newValue) {
        task_checklist.value[e.index][e.field] = e.newValue
        toast.add({severity: 'success', summary: 'Success', detail: 'Checklist aktualisiert', life: 3000})
    }
}

const reorder_checklist = (e) => {
    task_checklist.value = e.value
    toast.add({severity: 'success', summary: 'Success', detail: 'Sortierung der Checkliste aktualisiert', life: 3000})
}

const add_checklist = () => {
    task_checklist.value.push({"name": ""})
    toast.add({severity: 'success', summary: 'Success', detail: 'Checklist hinzugefügt', life: 3000})
}

const delete_checklist = (index) => {
    task_checklist.value.splice(index, 1)
    toast.add({severity: 'success', summary: 'Success', detail: 'Checklist gelöscht', life: 3000})
}

const task_details = (event) => {
    console.log(event.data)
    edit_date.value = false
    edit_due_date.value = false
    details.value = event.data
    details_slider.value = true
}

const open_url = (url) => {
    window.open(url, '_blank')
}

const copy_to_clipboard = (text) => {
    navigator.clipboard.writeText(text)
    toast.add({severity: 'success', summary: 'Success', detail: 'Copied to clipboard', life: 3000})
}

const get_company_name = (company_id) => {
    let company = companies.value.find(company => company.id == company_id)
    return company ? company.name : ''
}

const get_user_name = (email) => {
    let user = users.value.find(user => user.email == email)
    return user ? user.name : ''
}

const edit_due_date_conversion = () => {
    console.log("edit_due_date")
    let date = new Date(details.value.edit_due_date)
    date.setDate(date.getDate() + 1)
    details.value.due_date = date.toISOString()
    edit_due_date.value = false
}

const edit_date_conversion = () => {
    console.log("edit_date")
    let date = new Date(details.value.edit_date)
    date.setDate(date.getDate() + 1)
    details.value.date = date.toISOString()
    edit_date.value = false
}

//formatting

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    console.log("date input", value)
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}
</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toast />
    <ConfirmDialog />
    <BlockUI :fullScreen="true" :blocked="loader">
        <Toolbar class="mt-2">
            <template #start>
                <Button v-for="task_tab in task_tabs" :key="task_tab" rounded :label="task_tab.name" :icon="task_tab.icon" class="mr-2 bg-black-alpha-90 border-black-alpha-70" :class="filter_tasks.user.value == auth.userdata.user_email && task_tab.name == 'Meine Aufgaben' ? 'bg-primary border-primary' : ''" :badge="task_tab.badge" badgeSeverity="contrast" @click="set_task_filter(task_tab.name)" />
            </template>
        </Toolbar>
        <Toolbar class="mt-2">
            <template #start>
                <span class="p-input-icon-left" style="width: 350px">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_tasks['global'].value" placeholder="Search tasks" style="width: 350px" />
                </span>
            </template>
            <template #end>
                <Button label="Neue Aufgabe" icon="pi pi-plus" @click="toggle_menu_new_task" />
                <Menu ref="menu_new_task" :model="menu_new_task_items" :popup="true" />
            </template>
        </Toolbar>
        <div rounded class="overflow-hidden shadow-3 border-200 mt-4">
            <DataTable v-model:filters="filter_tasks" @row-click="task_details" :value="tasks" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,500,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} to {last} from {totalRecords}">
                <Column field="date" header="Datum">
                    <template #body="slotProps">
                        {{ format_swiss_date(slotProps.data.date) }}
                    </template>
                </Column>
                <Column field="name" header="Aufgabe" sortable>
                    <template #body="slotProps">
                        <Avatar icon="pi pi-file" shape="circle" class="mr-2 bg-primary text-white" />
                        <span class="font-bold mr-2">{{slotProps.data.name}}</span>
                    </template>
                </Column>
                <Column field="type" header="Typ" sortable>
                    <template #body="slotProps">
                        <Chip v-if="slotProps.data.type == 'Aufgabe'" class="mr-2 mb-1" :label="slotProps.data.type" />
                        <Chip v-if="slotProps.data.type == 'E-Mail'" class="mr-2 mb-1 bg-blue-200" :label="slotProps.data.type" />
                    </template>
                </Column>
                <Column field="status" header="Status" sortable>
                    <template #body="slotProps">
                        <Chip v-if="slotProps.data.status == 'open'" class="mr-2 mb-1" label="Offen" />
                        <Chip v-if="slotProps.data.status == 'progress'" class="mr-2 mb-1  bg-primary text-white" label="In Arbeit" />
                        <Chip v-if="slotProps.data.status == 'done'" class="mr-2 mb-1 bg-green-600 text-white" label="Erledigt" />
                    </template>
                </Column>
                <Column field="due_date" header="Fälligkeit" sortable>
                    <template #body="slotProps">
                        <span v-if="slotProps.data.due_date && slotProps.data.severity == 'danger'" class="text-red-600 font-bold">{{ format_swiss_date(slotProps.data.due_date) }}</span>
                        <span v-if="slotProps.data.due_date && slotProps.data.severity != 'danger'">{{ format_swiss_date(slotProps.data.due_date) }}</span>
                    </template>
                </Column>
                <Column field="user" header="Bearbeiter" sortable>
                    <template #body="slotProps">
                        <Chip class="mr-2 mb-1" :label="get_user_name(slotProps.data.user)" icon="pi pi-user" />
                    </template>
                </Column>
                <Column field="company" header="Kunde" sortable>
                    <template #body="slotProps">
                        <Chip v-if="get_company_name(slotProps.data.company)" class="mr-2 mb-1" :label="get_company_name(slotProps.data.company)" icon="pi pi-building" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <!-- DETAILS -->
    <Sidebar v-model:visible="details_slider" position="right" style="width: 50%">
        <div class="col-12 grid">
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                {{ details.type }}
            </div>
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="details.name" class="w-full" />
            </div>
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Datum
            </div>
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <Chip v-if="edit_date == false" :label="format_swiss_date(details.date)" class="mr-2 mb-1" />
                <Button v-if="edit_date == false" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-primary ml-2" @click="edit_date = true, details.date = ''" />
                <Calendar v-if="edit_date" showIcon v-model="details.edit_date" dateFormat="dd.mm.yy" class="w-full" @update:modelValue="edit_date_conversion()" />
            </div>
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Fälligkeit
            </div>
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <Chip v-if="details.due_date && details.severity == 'danger' && edit_due_date == false" :label="format_swiss_date(details.due_date)" class="bg-red-600 text-white font-bold mr-2 mb-1" />
                <Chip v-if="details.due_date && details.severity != 'danger' && edit_due_date == false" :label="format_swiss_date(details.due_date)" class="mr-2 mb-1" />
                <Button v-if="edit_due_date == false" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-primary ml-2" @click="edit_due_date = true, details.due_date = ''" />
                <Calendar v-if="edit_due_date" showIcon v-model="details.edit_due_date" dateFormat="dd.mm.yy" class="w-full" @update:modelValue="edit_due_date_conversion()" />
            </div>
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Kunden
            </div>
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0  align-content-center">
                <Dropdown placeholder="Firma auswählen" :options="companies" optionLabel="name" optionValue="id" v-model="details.company" class="w-full" />
            </div>
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Bearbeiter
            </div>
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <Dropdown v-if="details.user" :options="users" optionLabel="name" optionValue="email" v-model="details.user" class="w-full" />
            </div>
            <div v-if="details.type == 'E-Mail'" class="col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                <Button label="E-Mail bearbeiten" icon="pi pi-envelope" class="mr-2 w-auto" @click="router.push('/dashboard/Inbox?email='+details.email_id)" />
            </div>
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Beschreibung
            </div>
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <Editor v-model="details.description" class="w-full" editorStyle="height: 150px">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                            <button class="ql-image" v-tooltip.bottom="'Bild hochladen'"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div v-if="details.type != 'E-Mail'" class="col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Checkliste
            </div>
            <div v-if="details.type != 'E-Mail'" class="col-12 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <DataTable :value="details.checklist" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_edit_checklist" :rowHover="true" @rowReorder="reorder_edit_checklist">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="name" header="Aufgabe" style="">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span><i class="pi pi-check-square mr-2"></i>{{data[field]}}</span>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" @click="delete_edit_checklist(slotProps.index)" v-tooltip.top="'Aufgabe löschen'" />
                        </template>
                        <template #header="slotProps">
                            <Button icon="pi pi-plus" class="mr-2 p-button-rounded" @click="add_edit_checklist(slotProps)" v-tooltip.top="'Aufgabe hinzufügen'" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="col-10 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                <Button label="Speichern" icon="pi pi-save" class="mr-2 w-auto" @click="save_edit_task" />
                <Button label="Abbrechen" icon="pi pi-times" class="bg-red-600 text-white border-red-500 w-auto" @click="details_slider = false" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                <Button icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" @click="delete_task()" />
            </div>
        </div>
    </Sidebar>
    <!-- New ToDo Task -->
    <Dialog v-model:visible="todo_task_dialog" :style="{width: '70%'}" header="New ToDo Task" :modal="true" class="p-fluid z-5">
        <div class="p-fluid formgrid grid">
            <div class="col-12 mt-4">
                <span class="p-float-label">
                    <InputText id="task_name" v-model="task_name" />
                    <label for="task_name">Aufgabe</label>
                </span>
            </div>
            <div class="col-6 mt-4">
                <span class="p-float-label">
                    <Calendar id="due_date" dateFormat="dd.mm.yy" v-model="task_due_date" />
                    <label for="due_date">Fälligkeit (optional)</label>
                </span>
            </div>
            <div class="col-6 mt-4">
                <span class="p-float-label">
                    <Dropdown id="users" v-model="task_user" :options="users" optionLabel="name" optionValue="email" />
                    <label for="users">Bearbeiter</label>
                </span>
            </div>
            <div class="col-12 mt-4">
                <span class="p-float-label">
                    <Dropdown id="companies" v-model="task_company" filter :options="companies" optionLabel="name" optionValue="id" />
                    <label for="companies">Kunde (optional)</label>
                </span>
            </div>
            <div class="col-12 mt-4">
                <label for="description" class="font-bold">Beschreibung</label>
                <Editor id="description" v-model="task_description" class="mt-3 w-full" editorStyle="height: 150px">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                            <button class="ql-image" v-tooltip.bottom="'Bild hochladen'"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="col-12 mt-4">
                <label for="checklist" class="font-bold">Checkliste</label>
                <DataTable id="checklist" :value="task_checklist" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_checklist" :rowHover="true" @rowReorder="reorder_checklist">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="name" header="Aufgabe" style="">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span><i class="pi pi-check-square mr-2"></i>{{data[field]}}</span>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" @click="delete_checklist(slotProps.index)" v-tooltip.top="'Aufgabe löschen'" />
                        </template>
                        <template #header="slotProps">
                            <Button icon="pi pi-plus" class="mr-2 p-button-rounded" @click="add_checklist(slotProps)" v-tooltip.top="'Aufgabe hinzufügen'" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="col-12 mt-4">
                <label for="attachments" class="font-bold">Anhänge</label>
                <FileUpload mode="basic" accept="image/*" maxFileSize="1000000" @upload="onUpload" class="mt-2" @remove="onRemove" style="width: 200px" chooseLabel="Auswählen" />
            </div>
            <div class="col-12 mt-4">
                <Button label="Speichern" icon="pi pi-save" class="mr-2 w-auto" @click="save_task" />
                <Button label="Abbrechen" icon="pi pi-times" class="bg-red-600 text-white border-red-500 w-auto" @click="todo_task_dialog = false" />
            </div>
        </div>
    </Dialog>
</template>