<script setup>
import { ref, onMounted, useSSRContext, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import Toast from 'primevue/toast'

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const deals = ref([])
const deal = ref({})
const new_deal_dialog = ref(false)
const users = ref([])
const statuses = ref([])

const process_deal_slider = ref(false)

const filter_deals = ref({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    status: {value: "", matchMode: FilterMatchMode.EQUALS}
})

onMounted( async () => {
    get_deals()
    get_users()
    get_crm_statuses()
})

const get_deals = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-deals')
    .then(response => {
        console.log(response.data)
        deals.value = response.data
        loader.value = false
    })
}

const get_crm_statuses = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-crm-statuses')
    .then(response => {
        statuses.value = response.data
        console.log("statuses", statuses.value)
        loader.value = false
    })
}

const get_users = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-users')
    .then(response => {
        console.log(response.data)
        users.value = response.data
        loader.value = false
    })
}

const new_deal = () => {
    new_deal_dialog.value = true
}

const save_new_deal = () => {
    console.log("save deal")
}

const filter_status = (status) => {
    filter_deals.value.status.value = status
    console.log(filter_deals.value)
}

const count_status = (status) => {
    let count = 0
    for (let key in deals.value){
        if (deals.value[key].status == status) {
            count++
        }
    }
    return count
}

const get_status_name = (status_id) => {
    for (let key in statuses.value){
        if (statuses.value[key].id == status_id) {
            return statuses.value[key].name
        }
    }
}

const get_user_name = (email) => {
    for (let key in users.value){
        if (users.value[key].email == email) {
            return users.value[key].name
        }
    }
}

const org_files = []
const org_notes = []
const process_deal = (event) => {
    console.log(event.data)
    deal.value = event.data
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-organization-files', {"org_id": deal.value.org_id})
    .then(response => {
        org_files.value = response.data
        console.log("org_files", org_files.value)
    })
    axios.post()
    process_deal_slider.value = true
}

const open_url = (url) => {
    window.open(url, '_blank')
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + " " + value.substring(11,16)
}

</script>
<style>
.tab-primrary {
    background-color: #3751ff;
    color: #FFFFFF;
}
.tab-inactive {
    background-color: #0d2f3b;
    color: #FFFFFF;
}
.tab-hidden {
    display: none;
}
</style>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toast />
    <ConfirmDialog />
    <Toolbar>
        <template #start>
            <Button v-for="status in statuses" :key="status" rounded :label="status.name" @click="filter_status(status.name)" class="mr-2 bg-black-alpha-90 border-black-alpha-70 hover:bg-primary hover:border-primary" :class="filter_deals.status.value == status.name ? 'bg-primary border-primary' : ''" :badge="String(count_status(status.name))" badgeSeverity="contrast" />
        </template>
        <template #end>
            <Button label="Export" icon="pi pi-file-excel" v-tooltip.top="'Exportieren'" class="mr-2" />
        </template>
    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText v-model="filter_deals['global'].value" placeholder="Suche" class="w-full" @update:modelValue="filter_deals['status'].value = ''" />
            </span>
            <Button label="Aktualisieren" icon="pi pi-refresh" @click="get_deals()" class="w-auto mr-2" v-tooltip.right="'Aktualisieren'" />
        </template>
        <template #end>
            <Button label="Neuer Vertriebsprozess" icon="pi pi-play" @click="new_deal()" class="w-auto mr-2 bg-green-700 text-white border-green-600" v-tooltip.bottom="'Neuer Vertriebsprozess starten'" />
        </template>
    </Toolbar>
    <DataTable class="mt-3" v-model:filters="filter_deals" @row-click="process_deal" :value="deals" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,400,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column field="add_time" header="Startdatum" sortable>
            <template #body="slotProps">
                {{ format_swiss_date(slotProps.data.add_time) }}
            </template>
        </Column>
        <Column field="name" header="Firmenname">
            <template #body="slotProps">
                <span class="font-bold">{{slotProps.data.name}}</span>
            </template>
        </Column>
        <Column field="rep" header="Bearbeiter">
            <template #body="slotProps">
                <Chip :label="get_user_name(slotProps.data.rep)" class="bg-black-alpha-90 text-white" />
            </template>
        </Column>
        <Column field="update_time" header="Aktualisiert" sortable>
            <template #body="slotProps">
                {{ format_swiss_date_time(slotProps.data.update_time) }}
            </template>
        </Column>
        <Column header="Resourcen">
            <template #body="slotProps">
                <Chip class="bg-primary text-white mr-2" icon="pi pi-user" :label="String(slotProps.data.contacts)" v-tooltip.bottom="'Kontakte'" />
                <Chip class="bg-primary text-white mr-2" icon="pi pi-file-edit" :label="String(slotProps.data.notes_count)" v-tooltip.bottom="'Notizen'" />
                <Chip class="bg-primary text-white mr-2" icon="pi pi-paperclip" :label="String(slotProps.data.files_count)" v-tooltip.bottom="'Dateien'" />
                <Chip class="bg-red-700 text-white mr-2" icon="pi pi-bell" :label="String(0)" v-tooltip.bottom="'Kundenaufgaben'" />
            </template>
        </Column>
        <Column field="status" header="Status">
            <template #body="slotProps">
                <Chip :label="slotProps.data.status" class="bg-black-alpha-90 text-white font-bold" />
            </template>
        </Column>
    </DataTable>
    <!--------------------------------------------------------------------------->
    <!-------------------------------- DIALOGS ---------------------------------->
    <!--------------------------------------------------------------------------->
    <Dialog v-model:visible="new_deal_dialog" :style="{width: '1200px'}" header="Neuer Vertriebsprozess" :modal="true" class="p-fluid z-5">
        <div>test</div>
    </Dialog>
    <!--------------------------------------------------------------------------->
    <!-------------------------------- Slider ---------------------------------->
    <!--------------------------------------------------------------------------->
    <Sidebar header="Vetriebsprozess" v-model:visible="process_deal_slider" position="right" style="width: 70%">
        <TabView class="w-full mt-4">
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-folder" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Infos</span>
                    </div>
                </template>
                <div class="grid">
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Namen
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span class="mr-2">{{deal.name}}</span>
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Vertriebsprozess
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Dropdown v-model="deal.stage" :options="statuses" showClear optionLabel="name" optionValue="id" class="mr-2 w-auto" />
                    </div>
                    <div v-if="deal.stage == 1" class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Abklärungsgrund (optional)
                    </div>
                    <div v-if="deal.stage == 1" class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Editor v-model="deal.stage_1_reason" class="w-full" editorStyle="height: 150px">
                            <template v-slot:toolbar>
                                <span class="ql-formats">
                                    <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                    <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                    <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                    <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                    <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                                    <button class="ql-image" v-tooltip.bottom="'Bild hochladen'"></button>
                                </span>
                            </template>
                        </Editor>
                    </div>
                    <div v-if="deal.stage == 8" class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Offerte hochladen
                    </div>
                    <div v-if="deal.stage == 8" class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <FileUpload mode="basic" accept="application/pdf" chooseLabel="Offerte als PDF" maxFileSize="1000000" @upload="onUpload" />
                    </div>
                    <div v-if="deal.stage == 8" class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Beilagen hochladen
                    </div>
                    <div v-if="deal.stage == 8" class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <FileUpload mode="basic" accept="application/pdf" chooseLabel="Beilagen als PDF" maxFileSize="1000000" @upload="onUpload" />
                    </div>
                    <div v-if="deal.stage == 8 || deal.stage == 1" class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        <Button label="Speichern" icon="pi pi-save" class="w-auto mr-2 bg-green-700 text-white border-green-600" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-history" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Verlauf</span>
                        <Badge value="0" class="ml-2 bg-gray-100 text-black-alpha-90" />
                    </div>
                </template>
                history go here
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-file" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Dokumente</span>
                        <Badge value="0" class="ml-2 bg-gray-100 text-black-alpha-90" />
                    </div>
                </template>
                documents go here
            </TabPanel>
        </TabView>
    </Sidebar>
</template>