<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import { FilterMatchMode } from 'primevue/api'
import axios from 'axios'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

// filters
const filter_contacts = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                            'labels': {value: "", matchMode: FilterMatchMode.CONTAINS}})

// data
const contacts = ref([])
const contact = ref(null)
const statuses = ref([])
const contact_slider = ref(false)
const edit_contact = ref({name: false, email: false, phone: false})

onMounted( async () => {
    get_contacts()
    get_crm_statuses()
})

const get_contacts = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-contacts')
    .then(response => {
        console.log(response.data)
        contacts.value = response.data
        loader.value = false
    })
}

const save_contact = () => {
    console.log('save contact')
    toast.add({severity:'success', summary:'Kontakt gespeichert', life: 3000})
    edit_contact.value.name = false
    edit_contact.value.email = false
    edit_contact.value.phone = false
}

const get_crm_statuses = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-crm-statuses')
    .then(response => {
        statuses.value = response.data
        console.log("statuses", response.data)
        loader.value = false
    })
}

const new_contact = () => {
    console.log('new contact')
}

const new_deal = () => {
    console.log('new deal')
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/create-contact-deal', contact.value)
    .then(response => {
        console.log(response.data)
        toast.add({severity:'success', summary:'Vertriebsprozess gestartet', life: 3000})
        get_contacts()
    })
}

const contact_details = (event) => {
    console.log(event.data)
    contact.value = event.data
    contact_slider.value = true
}

const filter_type = (type) => {
    filter_contacts.value.labels.value = type
    console.log(filter_contacts.value)
}

const types = [
    {name: 'Kunde', value: 10},
    {name: 'Broker', value: 28}
]

const count_types = (type) => {
    return contacts.value.filter(contact => contact.labels.includes(type)).length
}

const open_url = (url) => {
    window.open(url, '_blank')
}
</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toast />
    <ConfirmDialog />
    <Toolbar>
        <template #start>
            <Button v-for="type in types" :key="type" rounded :label="type.name" @click="filter_type(type.value)" :badge="String(count_types(type.value))" badgeSeverity="contrast" class="mr-2 bg-black-alpha-90 border-black-alpha-70" />
        </template>
        <template #end>
            <Button label="Export" icon="pi pi-file-excel" v-tooltip.top="'Exportieren'" class="mr-2" />
        </template>
    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText v-model="filter_contacts['global'].value" placeholder="Suche" class="w-full" />
            </span>
            <Button label="Aktualisieren" icon="pi pi-refresh" @click="get_deals()" class="w-auto mr-2" v-tooltip.right="'Aktualisieren'" />
        </template>
        <template #end>
            <Button label="Neuer Kontakt" icon="pi pi-play" @click="new_contact()" class="w-auto mr-2 bg-green-700 text-white border-green-600" v-tooltip.bottom="'Neuer Kontakt erfassen'" />
        </template>
    </Toolbar>
    <div rounded class="overflow-hidden shadow-3 border-200 mt-4">
        <DataTable v-model:filters="filter_contacts" @row-click="contact_details" :value="contacts" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,500,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} to {last} from {totalRecords}">
            <Column field="name" header="Name">
                <template #body="slotProps">
                    <Avatar icon="pi pi-user" shape="circle" class="mr-2 bg-primary text-white" />
                    <span class="font-bold mr-2">{{slotProps.data.name}}</span>
                    <i v-if="slotProps.data.email" class="pi pi-envelope mr-2 pointer-cursor text-primary" />
                    <i v-if="slotProps.data.phone" class="pi pi-phone mr-2 pointer-cursor text-primary" />
                </template>
            </Column>
            <Column header="Typ" sortable>
                <template #body="slotProps">
                    <template v-for="label in slotProps.data.labels" :key="label">
                        <Chip v-if="label == 10" class="mr-2 mb-1 bg-blue-700 text-white" label="Kunde" />
                        <Chip v-if="label == 28" class="mr-2 mb-1 bg-orange-700 text-white" label="Broker" />
                    </template>
                </template>
            </Column>
            <Column field="company" header="Firma" sortable>
                <template #body="slotProps">
                    <Chip icon="pi pi-building" class="mr-2 mb-1" :label="slotProps.data.company" />
                </template>
            </Column>
            <Column field="deal" header="Vetrieb" sortable>
                <template #body="slotProps">
                    <Chip v-if="slotProps.data.deal" icon="pi pi-folder" :label="slotProps.data.deal" class="mr-2 bg-green-200 text-black-alpha-90" />
                </template>
            </Column>
            <Column field="broker" header="co" sortable>
                <template #body="slotProps">
                    <Chip v-if="slotProps.data.broker" icon="pi pi-lock" class="mr-2 mb-1 bg-red-700 text-white" :label="slotProps.data.broker" />
                </template>
            </Column>
            <Column field="phone" header="Telefon" hidden />
            <Column field="email" header="E-Mail" hidden />
        </DataTable>
    </div>
    <!----------------------------------------------------------------->
    <!-- Contact Slider -->
    <!----------------------------------------------------------------->
    <Sidebar v-model:visible.sync="contact_slider" position="right" style="width: 50%">
        <TabView class="w-full mt-4">
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-folder" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Infos</span>
                    </div>
                </template>
                <div class="col-12 grid">
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Namen
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-if="edit_contact.name" v-model="contact.name" class="w-full" />
                        <span v-else class="mr-2">{{contact.name}}</span>
                        <i v-if="!edit_contact.name" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_contact.name = true" />
                        <i v-if="edit_contact.name" class="pi pi-check cursor-pointer text-green-600 hover:text-black-alpha-90 ml-2 mt-1" @click="save_contact()" />
                        <i v-if="edit_contact.name" class="pi pi-times cursor-pointer text-red-600 hover:text-black-alpha-90 ml-2 mt-1" @click="edit_contact.name = false" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        E-Mail
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-if="edit_contact.email" v-model="contact.email" class="w-full" />
                        <i v-if="contact.email && !edit_contact.email" class="pi pi-envelope mr-2 cursor-pointer text-primary hover:text-black-alpha-90" @click="open_url('mailto:'+contact.email)" />
                        <span v-if="!edit_contact.email" class="mr-2">{{contact.email}}</span>
                        <i v-if="!edit_contact.email" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_contact.email = true" />
                        <i v-if="edit_contact.email" class="pi pi-check cursor-pointer text-green-600 hover:text-black-alpha-90 ml-2 mt-1" @click="save_contact()" />
                        <i v-if="edit_contact.email" class="pi pi-times cursor-pointer text-red-600 hover:text-black-alpha-90 ml-2 mt-1" @click="edit_contact.email = false" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Telefon
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-if="edit_contact.phone" v-model="contact.phone" class="w-full" />
                        <i v-if="contact.phone && !edit_contact.phone" class="pi pi-phone mr-2 cursor-pointer text-primary hover:text-black-alpha-90" @click="open_url('tel:'+contact.phone)" />
                        <span v-if="!edit_contact.phone" class="mr-2 cursor-pointer text-primary hover:text-black-alpha-90" @click="open_url('tel:'+contact.phone)">{{contact.phone}}</span>
                        <i v-if="!edit_contact.phone" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_contact.phone = true" />
                        <i v-if="edit_contact.phone" class="pi pi-check cursor-pointer text-green-600 hover:text-black-alpha-90 ml-2 mt-1" @click="save_contact()" />
                        <i v-if="edit_contact.phone" class="pi pi-times cursor-pointer text-red-600 hover:text-black-alpha-90 ml-2 mt-1" @click="edit_contact.phone = false" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Firma
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Chip icon="pi pi-building" class="mr-2" :label="contact.company" />
                        <i class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Typ
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span v-if="!edit_contact.labels">
                            <template v-for="label in contact.labels" :key="label">
                                <Chip v-if="label == 10" class="mr-2 mb-1 bg-blue-700 text-white" label="Kunde" />
                                <Chip v-if="label == 28" class="mr-2 mb-1 bg-orange-700 text-white" label="Broker" />
                            </template>
                        </span>
                        <i v-if="!edit_contact.labels" class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" @click="edit_contact.labels = true" />
                        <MultiSelect v-if="edit_contact.labels" v-model="contact.labels" :options="types" optionLabel="name" optionValue="value" class="w-full" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Broker
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Chip v-if="contact.broker" icon="pi pi-lock" class="mr-2 mb-1 bg-red-700 text-white" :label="contact.broker" />
                        <i class="pi pi-pencil cursor-pointer text-primary hover:text-black-alpha-90" />
                    </div>
                    <div v-if="!contact.deal" class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Vertriebsprozess starten
                    </div>
                    <div v-if="!contact.deal" class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Dropdown v-model="contact.deal" :options="statuses" showClear optionLabel="name" optionValue="id" class="mr-2" />
                        <Button :disabled="contact.deal ? false : true" icon="pi pi-play" @click="new_deal()" class="bg-green-700 text-white border-green-600" v-tooltip.bottom="'Neuer Vertriebsprozess starten'" />
                    </div>
                    <div v-if="contact.deal" class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-content-center flex-wrap">
                        Vertriebsprozess
                    </div>
                    <div v-if="contact.deal" class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Chip icon="pi pi-folder" :label="contact.deal" class="mr-2 bg-green-200 text-black-alpha-90" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-history" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Verlauf</span>
                        <Badge value="0" class="ml-2 bg-gray-100 text-black-alpha-90" />
                    </div>
                </template>
                Coming soon.
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-file" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Dokumente</span>
                        <Badge value="0" class="ml-2 bg-gray-100 text-black-alpha-90" />
                    </div>
                </template>
                Coming soon.
            </TabPanel>
        </TabView>
    </Sidebar>
</template>